import { i18n } from '@/i18n';

export default {
    namespaced: true,
    state: {
        connection: {/*
            connected: true/false,
            connectionType: 'wifi'/'cellular'
      */},
        language: 'da'
    },
    getters: {
        isOnline(state) {
            // assume we're connected until the event handler kicks in
            const connected = state.connection?.connected;
            return typeof connected === 'boolean' ? connected : true;
        }
    },
    mutations: {
        updateConnectionStatus(state, status) {
            state.connection = { ...status };
        },
        set_language(state, language = state.language) {
            state.language = language;
            i18n.global.locale.value = language;
        },
    },
    actions: {
        setup({ commit, rootGetters, state }) {
            const lang =
                rootGetters['users/me']?.lang ||
                new URLSearchParams(document.location.search).get('lang') ||
                state.language;

            commit('set_language', lang);
        }
    }
}
